import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import Resize from 'vuetify/lib/directives/resize';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,[_c(VRow,{staticClass:"mx-4 my-0 mb-n4"},[_c(VCol,[_c(VCardTitle,{staticClass:"px-0"},[_vm._v(" Letter of Authorization ")]),_c(VDivider)],1)],1),_c(VRow,{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.updatePDFScale),expression:"updatePDFScale"}],staticClass:"ma-0 loa-container"},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12","md":"7"}},[_c('loa-customer-form',_vm._g({attrs:{"company-id":_vm.companyId},on:{"change":_vm.updateCompany,"sign":_vm.onSigned}},_vm.$listeners))],1),_c(VCol,{ref:"pdfContainer",class:{ 'pa-7 pl-0': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"cols":"12","md":"5"}},[_c('sticky-media',{attrs:{"relative-element-selector":".loa-container","offset":{ top: 60, bottom: 0 },"enabled":!_vm.$vuetify.breakpoint.mobile}},[_c('loa-customer-preview',{ref:"pdf",attrs:{"announcement":_vm.announcement,"company":_vm.company,"signature":_vm.signature,"scale":_vm.scale}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }