import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import Resize from 'vuetify/lib/directives/resize';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"pa-0"},[_c('div',{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResizeDebounced),expression:"onResizeDebounced"}],ref:"container",staticClass:"wrapper mb-2",class:_vm.wrapperClass,style:(_vm.style)},[(!_vm.disabled)?_c('canvas',{ref:"canvas",staticClass:"signature-pad"}):_vm._e()]),(_vm.loading)?_c(VProgressLinear,{staticClass:"mb-2 mt-n2",attrs:{"height":"2","indeterminate":""}}):_vm._e(),_c(VRow,[_c(VCol,{attrs:{"cols":"auto"}},[(!_vm.disabled)?_c(VBtn,{attrs:{"small":"","disabled":_vm.isEmpty},on:{"click":_vm.clear}},[_vm._v(" Clear ")]):_vm._e()],1),_c(VCol,{staticClass:"pl-0 d-flex align-center"},[_vm._t("details")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }