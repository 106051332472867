<template>
    <v-container class="pa-0">
        <div
            ref="container"
            v-resize="onResizeDebounced"
            class="wrapper mb-2"
            :class="wrapperClass"
            :style="style"
        >
            <canvas
                v-if="!disabled"
                ref="canvas"
                class="signature-pad"
            ></canvas>
        </div>
        <v-progress-linear
            v-if="loading"
            height="2"
            class="mb-2 mt-n2"
            indeterminate
        />
        <v-row>
            <v-col cols="auto">
                <v-btn
                    v-if="!disabled"
                    small
                    :disabled="isEmpty"
                    @click="clear"
                >
                    Clear
                </v-btn>
            </v-col>
            <v-col class="pl-0 d-flex align-center">
                <slot name="details"></slot>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import Pad from 'signature_pad';
import { debounce } from '@/utils/helpers';

const SignaturePadProps = Vue.extend({
    name: 'SignaturePad',
    props: {
        width: {
            type: [Number, String],
            default() {
                return '100%';
            }
        },
        height: {
            type: [Number, String],
            default() {
                return '100%';
            }
        },
        value: {
            type: String,
            default() {
                return '';
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component<SignaturePad>({
    watch: {
        value() {
            this.useValue();
        }
    }
})
export default class SignaturePad extends SignaturePadProps {
    $refs!: {
        container: HTMLDivElement;
        canvas: HTMLCanvasElement;
    };

    pad: Pad | null = null;

    showValue = false;
    innerValue = '';

    get style() {
        return [
            `--width:${this.width}`,
            `--height:${this.height}px`,
            this.value ? `--value:url('${this.value}')` : null
        ]
            .filter(Boolean)
            .join(';');
    }

    get wrapperClass() {
        const classes = [];

        if (this.showValue) {
            classes.push('with-value');
        }

        if (this.loading) {
            classes.push('loading');
        }

        return classes.join(' ');
    }

    get isEmpty() {
        return !(this.showValue ? this.value : this.innerValue);
    }

    mounted() {
        if (!this.disabled) {
            this.pad = new Pad(this.$refs.canvas);

            this.pad.addEventListener('endStroke', this.onChange.bind(this));

            this.onResize();
        }
    }

    onResize() {
        if (!this.disabled) {
            const actual = this.pad?.toData();

            this.inheritContainerSize();

            if (actual) {
                this.pad?.fromData(actual);
            }
        }
    }

    inheritContainerSize() {
        this.$refs.canvas.width = this.$refs.container.offsetWidth;
        this.$refs.canvas.height = this.$refs.container.offsetHeight;
    }

    onResizeDebounced = debounce(this.onResize, 250);

    onChange() {
        this.innerValue = this.toPNG();

        this.onInput();
    }

    toPNG() {
        if (!this.pad || this.pad.isEmpty()) {
            return '';
        }

        return this.pad.toDataURL();
    }

    clear() {
        this.pad?.clear();

        this.innerValue = '';

        this.onInput();

        this.showValue = false;
    }

    async useValue() {
        if (this.value) {
            this.showValue = true;
        }
    }

    onInput() {
        this.$emit('input', this.innerValue);
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    position: relative;
    user-select: none;
    width: var(--width);
    height: var(--height);

    &:after {
        content: '';
        border: 2px dashed $tertiary-color;
        border-radius: 5px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s ease-in-out;
    }

    &:hover:after {
        opacity: 0.8;
    }

    &.with-value:before {
        content: '';
        background-image: var(--value);
        background-size: contain;
        background-position: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &.with-value:after,
    &.loading:after {
        z-index: 1;
        pointer-events: all;
        background-color: $seashell-solid;
    }

    &.with-value:hover:after {
        opacity: 0.3;
    }
}

.signature-pad {
    background-color: $white;
}
</style>
