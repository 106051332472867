<template>
    <v-container
        ref="container"
        v-resize="resizeContainer"
        class="loa-preview"
        :class="{
            'px-7': $vuetify.breakpoint.smAndDown,
            'px-10': $vuetify.breakpoint.mdAndUp
        }"
        :style="scaleVariable"
    >
        <v-row class="header flex-nowrap">
            <v-col cols="8" class="font-weight-bold">
                <h2>
                    {{ businessName }} Press Release Distribution
                    <br />
                    Authorization Form
                </h2>
            </v-col>
            <v-col class="py-2 px-0 d-flex justify-end align-center">
                <v-img
                    class="logo"
                    src="@/assets/img/logo.ampifire.com.svg"
                    max-width="160"
                />
            </v-col>
        </v-row>
        <v-row class="content">
            <v-col class="pt-2">
                <v-container class="pa-0">
                    <v-row>
                        <v-col class="pb-4"><h3>For Customer Use</h3></v-col>
                    </v-row>
                    <v-row dense class="bordered concrete-solid">
                        <v-col class="font-weight-bold">
                            Press Release Details
                        </v-col>
                    </v-row>
                    <v-row dense class="bordered concrete-solid">
                        <v-col class="col-10-2">Press Release Title</v-col>
                        <v-col class="data fit rows-1">
                            <div>
                                {{ headline }}
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row class="content mt-2">
            <v-col>
                <v-container class="pa-0">
                    <v-row dense class="bordered concrete-solid">
                        <v-col class="font-weight-bold">Company Info</v-col>
                    </v-row>
                    <v-row dense class="bordered concrete-solid">
                        <v-col class="col-10-2">Company Name</v-col>
                        <v-col class="col-10-3 data fit rows-1">
                            <div>
                                {{ company.name }}
                            </div>
                        </v-col>
                        <v-col class="col-10-2">Registration No.</v-col>
                        <v-col class="col-10-3 data fit rows-1">
                            <div>
                                {{ company.registration_number }}
                            </div>
                        </v-col>
                    </v-row>
                    <v-row dense class="bordered concrete-solid">
                        <v-col class="col-10-2">Company Address</v-col>
                        <v-col class="col-10-3 data">
                            {{ company.full_address }}
                        </v-col>
                        <v-col cols="6" class="d-flex">
                            <v-container class="pa-0 inner d-flex flex-column">
                                <v-row dense class="flex-grow-0">
                                    <v-col class="col-10-4">Telephone</v-col>
                                    <v-col class="col-10-6 data">
                                        {{ company.phone }}
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col class="col-10-4">Email</v-col>
                                    <v-col class="col-10-6 data fit rows-1">
                                        <div>
                                            {{ company.email }}
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                    <v-row dense class="bordered concrete-solid">
                        <v-col class="col-10-2">Entity Type</v-col>
                        <v-col class="col-10-3 data">
                            <div
                                v-for="entity in entities"
                                :key="entity.value"
                                class="d-flex align-center"
                            >
                                <v-icon
                                    x-small
                                    color="grey darken-1"
                                    class="mr-1"
                                >
                                    {{ entityIcon(entity.value) }}
                                </v-icon>
                                {{ entity.label }}
                            </div>
                            <div
                                v-if="
                                    isOtherEntity && company.entity_type_other
                                "
                                class="pl-4 fit rows-1"
                            >
                                <div>
                                    {{ company.entity_type_other }}
                                </div>
                            </div>
                            <div v-else class="rows-1">
                                <div key="blank" class="rows-blank">&nbsp;</div>
                            </div>
                        </v-col>
                        <v-col cols="6" class="d-flex">
                            <v-container class="pa-0 inner d-flex flex-column">
                                <v-row dense class="flex-grow-0">
                                    <v-col class="col-10-4">Website</v-col>
                                    <v-col class="col-10-6 data fit rows-1">
                                        <div>{{ company.website }}</div>
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col class="col-10-4">
                                        <!-- don't remove that &nbsp; here :) -->
                                        D-U-N-S Number (if&nbsp;available)
                                    </v-col>
                                    <v-col class="col-10-6 data">
                                        {{ company.duns_number }}
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row class="content mt-2">
            <v-col>
                <v-container class="pa-0">
                    <v-row dense class="bordered concrete-solid">
                        <v-col class="font-weight-bold">Media Contact</v-col>
                    </v-row>
                    <v-row dense class="bordered concrete-solid">
                        <v-col class="col-10-2">Name</v-col>
                        <v-col class="col-10-3 data fit rows-1">
                            <div>{{ company.media_contact_name }}</div>
                        </v-col>
                        <v-col class="col-10-2">Telephone</v-col>
                        <v-col class="col-10-3 data">
                            {{ company.media_contact_phone }}
                        </v-col>
                    </v-row>
                    <v-row dense class="bordered concrete-solid">
                        <v-col class="col-10-2">Designation</v-col>
                        <v-col class="col-10-3 data fit rows-1">
                            <div>
                                {{ company.media_contact_designation }}
                            </div>
                        </v-col>
                        <v-col class="col-10-2">Email</v-col>
                        <v-col class="col-10-3 data fit rows-1">
                            <div>
                                {{ company.media_contact_email }}
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row class="content mt-2">
            <v-col>
                <v-container class="pa-0">
                    <v-row dense class="bordered concrete-solid">
                        <v-col class="font-weight-bold">
                            Authorized Company Representative (PIC)
                        </v-col>
                    </v-row>
                    <v-row dense class="bordered concrete-solid">
                        <v-col class="col-10-2">Name</v-col>
                        <v-col class="col-10-3 data fit rows-1">
                            <div>
                                {{ company.representative_contact_name }}
                            </div>
                        </v-col>
                        <v-col class="col-10-2">Telephone</v-col>
                        <v-col class="col-10-3 data">
                            {{ company.representative_contact_phone }}
                        </v-col>
                    </v-row>
                    <v-row dense class="bordered concrete-solid">
                        <v-col class="col-10-2">Designation</v-col>
                        <v-col class="col-10-3 data fit rows-1">
                            <div>
                                {{ company.representative_contact_designation }}
                            </div>
                        </v-col>
                        <v-col class="col-10-2">Email</v-col>
                        <v-col class="col-10-3 data fit rows-1">
                            <div>
                                {{ company.representative_contact_email }}
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row class="content mt-2">
            <v-col>
                <v-container class="pa-0">
                    <v-row dense class="bordered">
                        <v-col>
                            <div class="d-flex align-center pl-1">
                                <v-icon x-small left color="grey darken-1">
                                    far fa-square-check
                                </v-icon>
                                I have sent an authorization email to
                                {{ businessEmail }} from authorized corporate
                                email address
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row class="content mt-2">
            <v-col>
                <v-container class="pa-0">
                    <v-row dense class="bordered">
                        <v-col>
                            <div class="d-flex align-center pl-1">
                                <v-icon x-small left color="grey darken-1">
                                    far fa-square-check
                                </v-icon>
                                I hereby authorize {{ businessName }} to send
                                the content of this amp to media distributors on
                                behalf of my company
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row class="content mt-2">
            <v-col>
                <v-container class="pa-0">
                    <v-row dense>
                        <v-col cols="6">
                            <h4>PRODUCT TERMS</h4>
                            <div class="pt-2">
                                {{ businessName }} do not accept content
                                containing or promoting: &bull;&nbsp;Illegal
                                content &bullet;&nbsp;Escort service, sexual
                                enhancement products or supplements
                                &bull;&nbsp;Make money online, trading, and
                                cryptocurrency &bull;&nbsp;Radical political and
                                religious opinion or view &bull;&nbsp;Harm or
                                defamation against any person or entity (If you
                                are unsure if your topic is suitable, please
                                contact us).
                            </div>
                            <div class="pt-4">
                                Public email domain like @gmail.com and generic
                                email handlers like info@ are also not accepted
                                as authorized representative.
                            </div>
                        </v-col>
                        <v-col class="d-flex" cols="6">
                            <v-container class="pa-0 d-flex flex-column">
                                <v-row dense class="bordered">
                                    <v-col class="d-flex flex-column">
                                        <div>Company Authorized Signatory</div>
                                        <div
                                            class="d-flex flex-grow-1 align-center signature"
                                        >
                                            <v-img
                                                :src="signature"
                                                contain
                                                max-height="190"
                                            />
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row dense class="bordered flex-grow-0">
                                    <v-col cols="4">Name</v-col>
                                    <v-col class="data fit rows-1">
                                        <div>
                                            {{
                                                company.authorized_contact_name
                                            }}
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row dense class="bordered flex-grow-0">
                                    <v-col cols="4">Designation</v-col>
                                    <v-col class="data fit rows-1">
                                        <div>
                                            {{
                                                company.authorized_contact_designation
                                            }}
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row dense class="bordered flex-grow-0">
                                    <v-col cols="4">Date</v-col>
                                    <v-col class="data">
                                        {{ date }}
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row class="footer">
            <v-col cols="12" class="text-right">
                Ampifire
                <br />
                MINDQUO LIMITED, 14/F CHINA HONG KONG TOWER 8, HENNESSY ROAD,
                WANCHAI, HONG KONG
                <br />
                support@ampifire.com
            </v-col>
        </v-row>
        <!-- we use this element for initial font size calculation, don't remove it -->
        <div id="font-base">&nbsp;</div>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { formatDate, innerDimensions } from '@/utils/helpers';

import type { Announcement } from '@/types/Announcement';
import type { Company } from '@/types/Company';

type CompanyEntity = {
    label: string;
    value: number;
};

const LoaCustomerPreviewProps = Vue.extend({
    name: 'LoaCustomerPreview',
    props: {
        announcement: {
            type: Object as PropType<Announcement | null>,
            default() {
                return {
                    press_release: null,
                    min_distribution_time: null
                };
            }
        },
        company: {
            type: Object as PropType<Partial<Company>>,
            default(): Partial<Company> {
                return {};
            }
        },
        signature: {
            type: String,
            default() {
                return '';
            }
        },
        scale: {
            type: Number,
            default() {
                return 1;
            }
        }
    }
});

@Component<LoaCustomerPreview>({
    computed: {
        ...mapState('user', ['businessName', 'businessEmail'])
    }
})
export default class LoaCustomerPreview extends LoaCustomerPreviewProps {
    $refs!: {
        container: HTMLDivElement;
    };

    businessName!: string | null;
    businessEmail!: string | null;

    entities: CompanyEntity[] = [
        {
            label: 'Sole Proprietor',
            value: 1
        },
        {
            label: 'Partnership',
            value: 2
        },
        {
            label: 'Limited Liability Company',
            value: 3
        },
        {
            label: 'Public Company',
            value: 4
        },
        {
            label: 'Non-profit',
            value: 5
        },
        {
            label: 'Other:',
            value: 0
        }
    ];

    get headline() {
        return this.pressRelease?.headline || '';
    }

    get pressRelease() {
        return this.announcement?.press_release || null;
    }

    get date() {
        return formatDate(
            this.announcement?.min_distribution_time || this.$date().format(),
            'DD/MM/YYYY'
        );
    }

    get isOtherEntity() {
        return (
            typeof this.company.entity_type !== 'undefined' &&
            +this.company.entity_type === 0
        );
    }

    get scaleVariable() {
        return `--paper-scale:${this.scale}`;
    }

    get baseFontSize(): number {
        const baseContainer = document.getElementById('font-base');

        if (baseContainer) {
            const fontSize = window
                .getComputedStyle(baseContainer)
                .getPropertyValue('font-size');

            return +parseFloat(fontSize).toFixed(2);
        }

        return 16; // default font
    }

    mounted() {
        this.$nextTick(() => {
            this.resizeContainer();
        });
    }

    updated() {
        this.$nextTick(() => {
            this.fit();
        });
    }

    entityIcon(value: number | string) {
        if (
            typeof this.company.entity_type !== 'undefined' &&
            +value === +this.company.entity_type
        ) {
            return 'far fa-square-check';
        }

        return 'far fa-square';
    }

    resizeContainer() {
        if (this.$vuetify.breakpoint.mobile) {
            const { height } = this.$el.getBoundingClientRect();

            if (this.$el.parentElement && height) {
                this.$el.parentElement.style.height = `${height}px`;
            }
        }
    }

    fit() {
        const elements = this.$el.querySelectorAll('.fit');

        [...elements].forEach(element => {
            this.shrink(element as HTMLDivElement);
        });
    }

    shrink(container: HTMLDivElement) {
        const { height } = innerDimensions(container);

        if (height) {
            const [wrapper] = container.querySelectorAll('div');

            if (wrapper) {
                wrapper.style.fontSize = `${this.baseFontSize}px`;

                const delta = (this.baseFontSize / 100) * 5;

                while (wrapper.offsetHeight > height) {
                    wrapper.style.fontSize = `${
                        +parseFloat(wrapper.style.fontSize) - delta
                    }px`;
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap');

.loa-preview {
    color: $black;
    font-family: 'Open Sans', 'DM Sans', system-ui;
    font-size: 80% !important;
    // A4 size
    width: 210mm;
    height: 297mm;
    transform: scale(var(--paper-scale));
    transform-origin: left top;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid $vuetify-grey;
        border-radius: 8px;
        pointer-events: none;
    }

    #font-base {
        display: none;
    }

    .fit {
        overflow: hidden;
    }

    .rows-1 {
        max-height: 28px;

        .rows-blank {
            height: 28px;
        }
    }

    .rows-2 {
        max-height: 56px;
    }

    .signature {
        // keep in sync with v-img max-height
        height: 190px;
    }
}

.logo {
    margin-right: 9px;
}

.header,
.footer {
    background-color: $vuetify-grey;
}

.footer {
    font-size: 75%;
}

.content {
    border-left: 1px solid $vuetify-grey;
    border-right: 1px solid $vuetify-grey;
}

.bordered {
    .col {
        border: 1px solid $light-black;

        + .col {
            border-left: none;
        }
    }

    + .bordered .col {
        border-top: none;
    }

    .data {
        background-color: $white;
    }

    .inner {
        .col {
            border-top: none;
            border-left: none;

            + .col {
                border-right: none;
            }
        }

        .row:last-of-type {
            .col {
                border-bottom: none;
            }
        }
    }
}
</style>
