<template>
    <v-card>
        <v-row class="mx-4 my-0 mb-n4">
            <v-col>
                <v-card-title class="px-0">
                    Letter of Authorization
                </v-card-title>
                <v-divider />
            </v-col>
        </v-row>
        <v-row v-resize="updatePDFScale" class="ma-0 loa-container">
            <v-col cols="12" md="7" class="pa-0">
                <loa-customer-form
                    :company-id="companyId"
                    @change="updateCompany"
                    @sign="onSigned"
                    v-on="$listeners"
                />
            </v-col>
            <v-col
                ref="pdfContainer"
                cols="12"
                md="5"
                :class="{ 'pa-7 pl-0': $vuetify.breakpoint.mdAndUp }"
            >
                <sticky-media
                    relative-element-selector=".loa-container"
                    :offset="{ top: 60, bottom: 0 }"
                    :enabled="!$vuetify.breakpoint.mobile"
                >
                    <loa-customer-preview
                        ref="pdf"
                        :announcement="announcement"
                        :company="company"
                        :signature="signature"
                        :scale="scale"
                    />
                </sticky-media>
            </v-col>
        </v-row>
    </v-card>
</template>

<script lang="ts">
import { Endpoint } from '@/mixins';
import { innerDimensions, scrollTop } from '@/utils/helpers';
import Component, { mixins } from 'vue-class-component';

import { StickyMedia } from '@/components/ASlideshow';

import LoaCustomerForm from './LoaCustomerForm.vue';
import LoaCustomerPreview from './LoaCustomerPreview.vue';

import type { Announcement } from '@/types/Announcement';
import type { Company } from '@/types/Company';

@Component({
    components: {
        StickyMedia,
        LoaCustomerForm,
        LoaCustomerPreview
    }
})
export default class LoaCustomer extends mixins(Endpoint) {
    $refs!: {
        pdf: LoaCustomerPreview;
        pdfContainer: HTMLDivElement;
    };

    announcement: Announcement | null = null;

    endpoint = '/announcements/publish';

    company: Partial<Company> = {};

    signature = '';

    scale = 0.5;

    get companyId() {
        return this.announcement?.company_id || 0;
    }

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get sourceUrl() {
        return [this.endpoint, this.announcementId].join('/');
    }

    onMounted() {
        // reset sticky preview position
        scrollTop();

        this.load();
    }

    onData(data: { announcement: Announcement }) {
        if (data.announcement) {
            this.announcement = data.announcement;
        }
    }

    updateCompany(company: Partial<Company>) {
        this.company = company;
    }

    onSigned(data: string) {
        this.signature = data;
    }

    updatePDFScale() {
        const { width } = innerDimensions(this.$refs.pdfContainer);
        const element = this.$refs.pdf.$el as HTMLDivElement;

        this.scale = width / element.offsetWidth;
    }

    updated() {
        this.updatePDFScale();
    }
}
</script>
