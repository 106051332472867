<template>
    <v-container>
        <v-btn
            text
            color="primary-color"
            class="mb-n1"
            title="Back to Publish Amp"
            @click="back"
        >
            <v-icon small left>arrow-left</v-icon>
            Back to Publish Amp
        </v-btn>
        <loa-customer
            class="pa-0 mt-4"
            @saved="back"
            @before-leave="setLeaveHook"
        />
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { LoaCustomer } from '@/components/LetterOfAuthorization/Customer';

import type { NavigationGuardNext, Route } from 'vue-router';

type OnLeaveHook = null | (() => Promise<boolean>);

Component.registerHooks(['beforeRouteLeave']);

@Component({
    components: {
        LoaCustomer
    }
})
export default class Loa extends Vue {
    onLeaveHook: OnLeaveHook = null;

    isLeaving = false;

    get announcementId() {
        return this.$route.params.announcementId;
    }

    back() {
        // onLeaveHook will trigger @saved event, causing double redirect
        // which we don't want
        if (!this.isLeaving) {
            this.$router.push(`/announcements/publish/${this.announcementId}`);
        }
    }

    beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
        if (this.onLeaveHook) {
            this.isLeaving = true;

            return this.onLeaveHook().then(() => next());
        } else {
            return next();
        }
    }

    setLeaveHook(hook: OnLeaveHook) {
        this.onLeaveHook = hook;
    }
}
</script>
