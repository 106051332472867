<template>
    <validation-provider
        ref="provider"
        v-slot="{ errors, validate, changed, validated }"
        slim
        :detect-input="false"
        :rules="rules"
        :name="fieldName"
        :vid="$attrs.vid"
    >
        <signature-pad
            :height="height"
            :value="value"
            :loading="loading"
            :class="rootClass"
            v-on="getListeners(validate, changed, validated)"
        >
            <template #details>
                <div
                    v-if="errors.length"
                    class="v-messages theme--light error--text"
                    role="alert"
                >
                    <div class="v-messages__wrapper">
                        <div class="v-messages__message">
                            {{ errors[0] }}
                        </div>
                    </div>
                </div>
            </template>
        </signature-pad>
    </validation-provider>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { ValidationProvider } from 'vee-validate';

import { SignaturePad } from '@/components/SignaturePad';

const ASignatureProps = Vue.extend({
    name: 'ASignature',
    props: {
        value: {
            type: String,
            default() {
                return '';
            }
        },
        label: {
            type: String,
            default() {
                return '';
            }
        },
        field: {
            type: String,
            default() {
                return '';
            }
        },
        name: {
            type: String,
            default() {
                return '';
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        },
        height: {
            type: [Number, String],
            default() {
                return 300;
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component<ASignature>({
    inheritAttrs: false,
    components: {
        SignaturePad,
        ValidationProvider
    },
    watch: {
        value(value: string) {
            if (this.innerValue !== value) {
                this.innerValue = value;

                if (this.isMounted) {
                    this.$refs.provider.syncValue(this.innerValue);
                }
            }
        }
    }
})
export default class ASignature extends ASignatureProps {
    _uid!: number;

    $refs!: {
        provider: InstanceType<typeof ValidationProvider>;
        pad: InstanceType<typeof SignaturePad>;
    };

    innerValue = '';

    isMounted = false;
    isChanged = false;
    isValidated = false;

    get id() {
        return this._uid;
    }

    get fieldName() {
        return this.field || this.label || this.name;
    }

    get rules() {
        return this.$attrs.rules;
    }

    get required() {
        return typeof this.rules === 'string'
            ? this.rules?.includes('required')
            : Object.keys(this.rules).includes('required');
    }

    get isValid() {
        if (this.innerValue || !this.required) {
            return Boolean(this.innerValue);
        }

        return this.isMounted ? this.$refs.provider.errors.length === 0 : true;
    }

    get rootClass() {
        const classes = [];

        if ((this.isChanged || this.isValidated) && !this.isValid) {
            classes.push('error-signature');
        }

        return classes.join(' ');
    }

    mounted() {
        // turn on validation
        this.isMounted = true;
    }

    getListeners(
        validate: (v: string) => Promise<{ valid: boolean }>,
        changed: boolean,
        validated: boolean
    ) {
        this.isChanged = changed;
        this.isValidated = validated;

        return {
            input: (value: string) => {
                this.innerValue = value.trim();

                if (this.isMounted && (this.innerValue || this.isChanged)) {
                    validate(this.innerValue);
                }

                this.$refs.provider.setFlags({
                    dirty: true,
                    changed: true,
                    touched: true
                });

                this.$emit('input', this.innerValue);
            }
        };
    }
}
</script>

<style lang="scss" scoped>
.error-signature::v-deep {
    .wrapper:after {
        content: '';
        border: 2px dashed $error;
        border-radius: 5px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.8;
        pointer-events: none;
        transition: opacity 0.2s ease-in-out;
    }
}
</style>
