<template>
    <v-container>
        <a-form ref="form" :submit="save" :disabled="isLoading" focus-on-error>
            <v-card-text>
                <v-row dense class="mb-n4">
                    <v-col>
                        <h3>Company Info</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-alert elevation="2" class="body-2">
                            <template #prepend>
                                <v-icon small left>info-circle</v-icon>
                            </template>
                            If you need to edit your company info, please make
                            this change on the
                            <router-link
                                :is="companyId ? 'router-link' : 'span'"
                                :to="companyEditLink"
                                class="d-inline-block"
                            >
                                company page
                            </router-link>
                            .
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" md="6">
                        <a-phone-input
                            v-model="company.phone"
                            vid="company.phone"
                            label="Telephone"
                            rules="required"
                            :loading="isBusy"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <a-text-input
                            v-model="company.duns_number"
                            label="D-U-N-S Number"
                            rules="numeric|max:9"
                            :loading="isBusy"
                        />
                    </v-col>
                    <v-col cols="12">
                        <label class="font-weight-medium">Entity Type *</label>
                        <v-radio-group
                            v-model="company.entity_type"
                            dense
                            class="mt-2"
                            :value-comparator="entityTypeValue"
                            :loading="isBusy"
                        >
                            <v-row>
                                <v-col cols="12" md="auto">
                                    <v-radio
                                        label="Sole Proprietor"
                                        value="1"
                                    />
                                    <v-radio label="Public Company" value="4" />
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="auto"
                                    :class="{
                                        'pt-0': $vuetify.breakpoint.smAndDown
                                    }"
                                >
                                    <v-radio label="Partnership" value="2" />
                                    <v-radio label="Non-profit" value="5" />
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="auto"
                                    :class="{
                                        'pt-0': $vuetify.breakpoint.smAndDown
                                    }"
                                >
                                    <v-radio
                                        label="Limited Liability Company"
                                        value="3"
                                    />
                                    <v-radio
                                        label="Other, please specify"
                                        value="0"
                                    />
                                </v-col>
                            </v-row>
                        </v-radio-group>
                        <a-text-input
                            v-model="company.entity_type_other"
                            label="Other"
                            :disabled="!isOtherEntity"
                            :rules="entytTypeOtherRules"
                            :loading="isBusy"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <a-text-input
                            v-model="company.registration_number"
                            label="Registration Number"
                            rules="required|max:100"
                            :loading="isBusy"
                        />
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>
                        <h3>Media Contact</h3>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" md="6">
                        <a-text-input
                            v-model="company.media_contact_name"
                            vid="company.media_contact_name"
                            label="Name"
                            rules="required|max:255"
                            :loading="isBusy"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <a-phone-input
                            v-model="company.media_contact_phone"
                            vid="company.media_contact_phone"
                            label="Telephone"
                            rules="required"
                            :loading="isBusy"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <a-text-input
                            v-model="company.media_contact_designation"
                            vid="company.media_contact_designation"
                            label="Designation"
                            rules="required|max:255"
                            :loading="isBusy"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <a-text-input
                            v-model="company.media_contact_email"
                            vid="company.media_contact_email"
                            label="Email"
                            rules="required|email|max:255"
                            :loading="isBusy"
                        />
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>
                        <h3>Authorized Company Representative (PIC)</h3>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" md="6">
                        <a-text-input
                            v-model="company.representative_contact_name"
                            vid="company.representative_contact_name"
                            label="Name"
                            rules="required|max:255"
                            :loading="isBusy"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <a-phone-input
                            v-model="company.representative_contact_phone"
                            vid="company.representative_contact_phone"
                            label="Telephone"
                            rules="required"
                            :loading="isBusy"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <a-text-input
                            v-model="company.representative_contact_designation"
                            vid="company.representative_contact_designation"
                            label="Designation"
                            rules="required|max:255"
                            :loading="isBusy"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <a-text-input
                            v-model="company.representative_contact_email"
                            vid="company.representative_contact_email"
                            label="Email"
                            field="Authorized Company Representative"
                            rules="required|email|no_free_domains|max:255"
                            :loading="isBusy"
                        />
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>
                        <h3>Company Authorized Signatory</h3>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>
                        <a-signature
                            class="pb-4"
                            height="300"
                            vid="company.company_signatory"
                            field="Company Authorized Signatory"
                            rules="required"
                            :value="signature"
                            :loading="isBusy"
                            @input="onSigned"
                        />
                        <a-media-uploader
                            ref="uploader"
                            class="d-none"
                            :options="signatureOptions"
                            :allow-process="false"
                            @queue="queueJob"
                        />
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" md="6">
                        <a-text-input
                            v-model="company.authorized_contact_name"
                            vid="company.authorized_contact_name"
                            label="Name"
                            rules="required|max:255"
                            :loading="isBusy"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <a-phone-input
                            v-model="company.authorized_contact_phone"
                            vid="company.authorized_contact_phone"
                            label="Telephone"
                            rules="required"
                            :loading="isBusy"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <a-text-input
                            v-model="company.authorized_contact_designation"
                            vid="company.authorized_contact_designation"
                            label="Designation"
                            rules="required|max:255"
                            :loading="isBusy"
                        />
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions class="mx-2 pb-4 pt-0">
                <v-btn
                    type="submit"
                    color="primary"
                    :loading="isSaving"
                    :block="$vuetify.breakpoint.smAndDown"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </a-form>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';

import { Endpoint } from '@/mixins';

import { AForm } from '@/components/AForm';

import { APhoneInput } from '@/components/AForm/Inputs/APhoneInput';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';
import { AMediaUploader } from '@/components/AForm/Inputs/AMediaUploader';
import { ASignature } from '@/components/AForm/Inputs/ASignature';

import type { ServerResponse } from '@/types/ServerResponse';
import type { MediaResource } from '@/types/MediaResource';

type QueueItem = () => Promise<void>;

const LoaCustomerFormProps = Vue.extend({
    name: 'LoaCustomerForm',
    props: {
        companyId: {
            type: [Number, String],
            default() {
                return 0;
            }
        }
    }
});

@Component<LoaCustomerForm>({
    components: {
        AForm,
        APhoneInput,
        ATextInput,
        AMediaUploader,
        ASignature
    },
    watch: {
        companyId() {
            this.onMounted();
        },
        company: {
            deep: true,
            handler() {
                this.$emit('change', this.company);
            }
        }
    }
})
export default class LoaCustomerForm extends mixins(
    LoaCustomerFormProps,
    Endpoint
) {
    $refs!: {
        form: AForm;
        uploader: AMediaUploader;
    };

    isSaving = false;

    endpoint = '/companies/edit';

    company = {
        id: 0,
        user_id: 0,
        contact_name: '',
        name: '',
        company_signatory: null as MediaResource | null,

        phone: '',
        duns_number: '',
        registration_number: '',
        entity_type: 0,
        entity_type_other: '',

        media_contact_name: '',
        media_contact_phone: '',
        media_contact_email: '',
        media_contact_designation: '',

        representative_contact_name: '',
        representative_contact_phone: '',
        representative_contact_email: '',
        representative_contact_designation: '',

        authorized_contact_name: '',
        authorized_contact_phone: '',
        authorized_contact_designation: ''
    };

    propsToSave = new Set([
        'contact_name',
        'phone',
        'duns_number',
        'registration_number',
        'entity_type',
        'entity_type_other',

        'media_contact_name',
        'media_contact_phone',
        'media_contact_email',
        'media_contact_designation',

        'representative_contact_name',
        'representative_contact_phone',
        'representative_contact_email',
        'representative_contact_designation',

        'authorized_contact_name',
        'authorized_contact_phone',
        'authorized_contact_designation'
    ]);

    batch: QueueItem[] = [];

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get sourceUrl() {
        return [this.endpoint, this.companyId].join('/');
    }

    get companyEditLink() {
        return `/announcements/publish/${this.announcementId}/loa/company/${this.companyId}`;
    }

    get entytTypeOtherRules() {
        return this.isOtherEntity ? 'required|max:64' : 'max:64';
    }

    get isOtherEntity() {
        return +this.company.entity_type === 0;
    }

    get isBusy() {
        return this.isLoading || this.isSaving || this.isUpdating;
    }

    get signatureOptions() {
        return {
            media_resource: {
                company_signatory_id: this.company.id
            },
            user_id: this.company.user_id,
            title: this.company.name
        };
    }

    get signature() {
        return (
            this.company.company_signatory?.media_file.relative_filename || ''
        );
    }

    onMounted() {
        if (this.companyId) {
            this.setLeaveHook();

            return this.load();
        }
    }

    setLeaveHook(isSet = true) {
        this.$emit('before-leave', isSet ? this.onLeave.bind(this) : null);
    }

    onData(data: { company: LoaCustomerForm['company'] }) {
        if (data.company) {
            this.company = data.company;
            // let other components use actual signature
            this.emitSignature();

            this.validate();
        }
    }

    entityTypeValue(a: number | string, b: number | string) {
        return +a === +b;
    }

    setSaving(isSaving = true) {
        this.isSaving = isSaving;
    }

    onLeave() {
        this.setLeaveHook(false);

        if (this.$refs.form.isDirty) {
            return this.save();
        }

        return Promise.resolve();
    }

    async save() {
        this.setSaving();

        return this.setData()
            .then(response => {
                if (!response?.meta?.success) {
                    this.$store.dispatch(
                        'notification/error',
                        'Unable to save Letter of Authorization. Please check the form for errors.'
                    );

                    return;
                }

                return this.processQueue()
                    .then(() => {
                        this.$store.dispatch(
                            'notification/success',
                            'Letter of Authorization saved'
                        );

                        this.onSave(response);
                    })
                    .catch(() => {
                        this.$store.dispatch(
                            'notification/error',
                            'Failed to process the batch'
                        );
                    });
            })
            .finally(this.setSaving.bind(this, false));
    }

    setData() {
        return this.$http
            .post(`/companies/edit/${this.company.id}`, this.getDataToSave())
            .then(({ data }) => data)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            });
    }

    getDataToSave() {
        return Object.entries(this.company).reduce(
            (
                acc: Record<
                    string,
                    string | number | boolean | null | MediaResource
                >,
                [key, value]
            ) => {
                if (this.propsToSave.has(key)) {
                    acc[key] = value;
                }

                return acc;
            },
            {}
        );
    }

    onSave(response: ServerResponse<{ company: LoaCustomerForm['company'] }>) {
        this.company = response.data.company;

        this.$emit('saved', response);
    }

    queueJob(handler: QueueItem) {
        this.batch.push(handler);
    }

    processQueue() {
        return Promise.all(this.batch.map(handler => handler()));
    }

    onSigned(data: string) {
        this.emitSignature(data);

        this.setSignature(data);
    }

    async setSignature(data: string) {
        if (data) {
            const blob = await fetch(data).then(r => r.blob());
            // prepare for uploading
            this.$refs.uploader.add('0', blob);
        } else {
            this.$refs.uploader.remove();
        }
    }

    emitSignature(data = this.signature) {
        this.$emit('sign', data);
    }

    validate() {
        this.$nextTick(() => {
            this.$refs.form.validate();
        });
    }
}
</script>
